<template>
  <div class="file-image clearfix">
    <div v-show="isReady" class="buttons">
      <el-button type="primary" icon="el-icon-download" circle @click="handleDownload" />
      <el-button v-if="downloading" type="primary" icon="el-icon-loading" circle @click="handleDownload" />
      <el-button v-show="deleteAction" type="primary" circle icon="el-icon-delete" @click="handleRemove" />
    </div>
    <span v-show="!isReady" class="status">{{ Math.floor(file.percentage) + '% ' }} </span>
    <span> {{ $t('fileUpload.fileName') }}: {{ file.name }}</span>
    <span class="description">{{ file.description }}</span>
    <div v-show="!isReady" class="progress-bar" :style="'width:' + file.percentage + '%'"></div>
  </div>
</template>

<script>
import { openFileStreamAs } from '../../utils';
export default {
  name: 'FileImage',
  props: {
    file: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    downloadAction: String,
    deleteAction: String
  },
  data() {
    return {
      dialogVisible: false,
      actionLoading: false,
      downloading: false
    };
  },
  computed: {
    isReady() {
      return this.file && (this.file.status === 'success' || this.file.id);
    },
    entityID() {
      return this.$route.params && this.$route.params.id;
    }
  },
  methods: {
    handleDownload() {
      this.downloadBlob(this.entityID);
    },
    async downloadBlob(id) {
      let contents = null;
      this.downloading = true;
      try {
        await this.downloadRequest(id).then(results => {
          contents = results.data;
          if (this.openFileInNewWindow) {
            openFileStreamAs(contents);
            return;
          }
          this.downloadFile(contents, this.file.name);
        });
      } catch (err) {
        console.log('download error', err);
      } finally {
        this.downloading = false;
      }
      return contents;
    },
    async downloadRequest(id) {
      const results = await this.$store.dispatch(this.downloadAction, id);
      return results;
    },
    downloadFile(data, name = 'file') {
      const anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(data);
      anchor.download = name;
      anchor.click();
    },
    handleRemove() {
      this.actionLoading = true;
      this.$store
        .dispatch(this.deleteAction, this.entityID)
        .then(() => {
          this.$emit('remove', this.file);
        })
        .finally(() => {
          this.actionLoading = false;
        });
    }
  }
};
</script>
