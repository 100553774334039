import MDinput from '@/components/MDinput';
import DSESelect from '@/components/DSE/DSESelect';
import { validatorRequire } from '@/utils/validators';
import { DatePicker } from 'element-ui';
import FileUpload from '@/components/DSE/FileUpload';
const today = new Date().toISOString().substr(0, 10);

export function getFormDefinition(vue) {
  return {
    dossierID: {
      type: MDinput,
      props: {
        readonly: true
      }
    },
    documentTypeID: {
      type: DSESelect,
      props: {
        caption: 'document.documentType',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['documentType/isLoading'],
        options: vue.$store.getters['documentType/loadedItems']
      }
    },
    description: {
      type: MDinput,
      props: {
        caption: 'document.description'
      }
    },
    createdate: {
      type: DatePicker,
      defaultValue: today,
      props: {
        caption: 'document.createDate',
        required: true,
        format: 'dd-MM-yyyy'
      },
      rules: [{ validator: validatorRequire }]
    },
    documentStatusID: {
      type: DSESelect,
      defaultValue: 1,
      props: {
        caption: 'document.status',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['documentStatus/isLoading'],
        options: vue.$store.getters['documentStatus/loadedItems']
      }
    },
    rejectReason: {
      type: MDinput,
      props: {
        caption: 'document.rejectReason'
      }
    },
    name: {
      type: FileUpload,
      isImageYN: false,
      showDownloadButton: true,
      hideOnCreate: true,
      caption: 'document.contents',
      props: {
        openFileInNewWindow: true,
        uploadRoute: 'document/content',
        deleteAction: 'document/deleteDocument',
        downloadAction: 'document/downloadDocument'
      }
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}
